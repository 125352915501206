<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row" v-if="userPermition">
				<div class="col-md-3 text-center">
					<div class="card bg-purple border-0 panel">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21">Limpio</div>
								<div class="font-size-14">
									<div class="row">
										<div class="col-md-12 text-center">
											{{ numeral(cash.panel.available).format('$0,0.00') }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bgGreen border-0 pointer pacific" @click="onBudgetAllocation('pacific')">
						<div class="card-body pointer">
							<div class="font-weight-bold">
								<div class="font-size-21">Productos</div>
								<div class="font-size-14">
									<div class="row">
										<div class="col-md-12 text-center">
											{{ numeral(cash.pacific.available).format('$0,0.00') }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bgRls border-0 pointer rls" @click="onBudgetAllocation('rls')">
						<div class="card-body pointer">
							<div class="font-weight-bold">
								<div class="font-size-21">Mano de Obra</div>
								<div class="font-size-14">
									<div class="row">
										<div class="col-md-12 text-center">
											{{ numeral(cash.rls.available).format('$0,0.00') }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-center">
					<div class="card bgSoftGreen border-0">
						<div class="card-body">
							<div class="font-weight-bold">
								<div class="font-size-21">Préstamo S2R</div>
								<div class="font-size-14">
									<div class="row">
										<div class="col-md-12 text-center">{{ numeral(cash.loan.s2r.available).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div :class="filters.status == 'C' ? 'col-md-12' : 'col-md-3'" class="text-right">
					<a-button class="btn btn-purple" @click="onAddRecord(true)" v-if="userPermition && filters.status == 'C'">Nueva ODC</a-button>
					<a-button class="btn btn-panel ml7" @click="onAddRecord(false)" v-if="filters.status == 'C'">Nueva ODC</a-button>
					<a-button class="btn btn-rls ml7" @click="onAddRecord(false, true)" v-if="userPermition && filters.status == 'C'">Nueva ODC</a-button>

					<a-button class="btn btn-info ml7" @click="onAddRecordTax" v-if="userPermition && filters.status == 'C'">Referenciados</a-button>
					<a-button class="btn btn-info ml7" @click="onAddBankCommission" v-if="userPermition && filters.status == 'C'">Comisiones</a-button>
					<a-button class="btn btn-info ml7" @click="onAddRecordPayroll" v-if="userPermition && filters.status == 'C'">Socios</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" v-if="filters.status == 'C'" />
				</div>
				<div :class="filters.status == 'C' ? 'col-md-12 pt20' : 'col-md-9'" class="text-right">
					<a-radio-group :value="filters.status" @change="handleStatusChange">
						<a-radio-button value="C"> Cotización </a-radio-button>
						<a-radio-button value="0"> Por autorizar </a-radio-button>
						<a-radio-button value="1"> Por pagar </a-radio-button>
						<a-radio-button value="2"> Pagadas </a-radio-button>
						<a-radio-button value="3" v-if="userPermition"> Facturadas </a-radio-button>
						<a-radio-button value="4" v-if="userPermition"> Validadas </a-radio-button>
					</a-radio-group>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" v-if="filters.status != 'C'" />
				</div>
			</div>

			<div class="row">
				<div class="col-md-3" v-if="isUserAdmin">
					<b># de ODC</b>
					<a-input v-model="filters.ticket" v-mask="'#####'" v-on:keyup.enter="searchByTicket" allowClear @change="onResetTicket" />
				</div>
				<div class="col-md-3" v-if="showFilters">
					<b>Rango de Fecha <small>(Registro)</small></b>
					<a-range-picker style="width: 100%" @change="initModule(true)" v-model="defaultDate" :default-value="defaultDate" :format="dateFormat" />
				</div>
				<div class="col-md-3" v-if="isUserAdmin && showFilters">
					<b>Proveedor</b>
					<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="filters.supplier_id" style="width: 100%" @change="initModule(false)">
						<a-select-option v-for="(supplier, index) in suppliersList" :key="index" :value="supplier.id">{{ supplier.tradename.toUpperCase() }}</a-select-option>
					</a-select>
					<small @click="getSuppliersList" class="pointer">Recarga</small>
				</div>
			</div>
			<div class="row pt20" :class="Number(filters.status) == 2 ? '' : 'pt20'" v-if="filters.status == 'R'">
				<div class="col-md-12">
					<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="getColumns()" :dataSource="purchaseOrdersList" rowKey="id" bordered class="pt20">
						<div slot="description" slot-scope="record">
							{{ `${record.quantity}` }}x {{ record.measurement_unit == 'piece' ? 'Pieza' : record.measurement_unit == 'liter' ? 'Litro' : 'Servicio' }} - {{ record.description }}
						</div>
						<div slot="service_order" slot-scope="record">
							<a-tag color="purple" v-if="record.service_order > 0">ODS {{ record.service_order }}</a-tag>
							<span v-else>- N/A -</span>
						</div>
						<div slot="action" slot-scope="record">
							<button class="btn btn-danger ant-btn-sm ant-btn-icon-only ml7" v-if="userPermition" @click="onDelete(record.id)">
								<a-icon type="delete" />
							</button>
							<span v-else>- N/A -</span>
						</div>
					</a-table>
				</div>
				<div class="col-md-12 text-right">
					<a-button class="btn btn-primary ml7" @click="onAuthorize" v-if="selectedRowKeys.length">Generar ODC de {{ selectedRowKeys.length }} elementos</a-button>
				</div>
			</div>
			<div class="row pt10" v-else>
				<div class="col-md-12" v-if="filters.status == '2'">
					<div class="row" v-if="userPermition">
						<div class="col-md-4 text-center">
							<div class="card bg-purple border-0 panel">
								<div class="card-body">
									<div class="font-weight-bold">
										<div class="font-size-21">Egresos Limpio</div>
										<div class="font-size-14">
											<div class="row">
												<div class="col-md-12 text-center">
													{{ numeral(cash.panel.out.cash).format('$0,0.00') }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 text-center">
							<div class="card bgGreen border-0 pacific">
								<div class="card-body">
									<div class="font-weight-bold">
										<div class="font-size-21">Egresos Productos</div>
										<div class="font-size-14">
											<div class="row">
												<div class="col-md-12 text-center">Efectivo: {{ numeral(cash.pacific.out.cash).format('$0,0.00') }} || Digital: {{ numeral(cash.pacific.out.digital).format('$0,0.00') }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 text-center">
							<div class="card bgRls border-0 rls">
								<div class="card-body">
									<div class="font-weight-bold">
										<div class="font-size-21">Egresos Mano de Obra</div>
										<div class="font-size-14">
											<div class="row">
												<div class="col-md-12 text-center">Efectivo: {{ numeral(cash.rls.out.cash).format('$0,0.00') }} || Digital: {{ numeral(cash.rls.out.digital).format('$0,0.00') }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-12">
					<a-table
						:columns="getColumns()"
						:dataSource="purchaseOrdersList"
						bordered
						:rowClassName="
							(_record) => {
								if (_record.from_pacific) {
									return 'bgGreen'
								}
								if (_record.from_panel) {
									return 'bg-purple'
								}
								if (_record.from_rls) {
									return 'bgRls'
								}
							}
						"
					>
						<div slot="payment_type" slot-scope="record">
							<div>
								{{ record.tradename | replaceIfLogicalFalse(record.business_name) }}
							</div>
							<div v-if="record.is_tax">
								{{ record.elements[0].description.toUpperCase() }}
								<br />
								<a-tag color="blue">Digital</a-tag>
							</div>
							<div v-else-if="record.payment_method_data">
								<a-tag color="green" v-if="JSON.parse(record.payment_method_data).payment_method == 1"> Efectivo </a-tag>
								<a-tag color="blue" v-else>Digital</a-tag>
							</div>
							<div v-else>- No establecido -</div>
							<a-tag color="pink" v-if="record.sat_payment_method == 'NEXT'"> Por pagar: {{ moment(record.credit_payment_date).format('DD-MM-YYYY') }} </a-tag>
							<div v-if="record.sat_payment_method == 'PPD'">
								<div v-for="(paymentDate, ppdIndex) in record.ppd_payments" :key="ppdIndex">
									<a-tag color="pink" v-if="!paymentDate.paymentReceipt"> Por pagar: {{ moment(paymentDate.paymentDate).format('DD-MM-YYYY') }} </a-tag>
								</div>
							</div>
						</div>
						<div slot="id" slot-scope="record">
							<div>{{ record.id }}</div>
							<div v-if="record.from_loan" class="fs10">Préstamo Bancario</div>
						</div>
						<div slot="order_date" slot-scope="record">
							<div>{{ moment(record.created_at).format('DD-MM-YYYY') }}</div>
							<div>{{ record.fullName }}</div>
						</div>
						<div slot="payment_date" slot-scope="record">
							<div>{{ record.payment_date ? moment(record.payment_date).format('DD-MM-YYYY') : '- Pago parcial -' }}</div>
						</div>
						<div slot="order" slot-scope="record">
							<span style="text-decoration: underline" class="pointer" @click="onOpenModal(record.elements)"
								>{{
									`${record.elements.length} ${
										record.elements.length == 1
											? `elemento
								solicitado`
											: `elementos solicitados`
									} `
								}}
							</span>
						</div>
						<div slot="amount" slot-scope="record">
							{{ numeral(record.total).format('$0,0.00') }}
						</div>
						<div slot="action" slot-scope="record">
							<a-button class="btn btn-success" icon="check" v-if="userPermition && record.status == 0 && record.blocked == true" @click="onChangeStatus(record.id)" />
							<a-button class="ml7 btn" :class="record.total ? 'btn-warning' : 'btn-info'" :icon="record.total ? 'eye' : 'edit'" @click="onEditRecord(record.id, record.from_rls)" />
							<a-button class="ml7 btn btn-danger" icon="close" v-if="record.status == 0" @click="onDisableRecord(record.id)" />
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal width="30%" :visible="orderModal" :footer="null" title="Detalle de pedido" :closable="true" @cancel="onCloseModal">
			<div v-for="(element, index) in elements" :key="index">
				{{ element.quantity }}x <span v-if="element.measurement_unit">{{ element.measurement_unit == 'liter' ? 'litro' : element.measurement_unit == 'piece' ? 'pieza' : 'servicio' }}</span> -
				{{ element.description }}
				<div v-if="element.service_order > 0">
					<a-tag color="purple">ODS {{ element.service_order }}</a-tag>
				</div>
				<div v-if="element.sku">
					<small>{{ element.sku }}</small>
				</div>
			</div>
		</a-modal>
		<a-modal width="50%" :visible="modalPayrollVisible" title="Pago a socios" :closable="true" @cancel="onCloseModalPayroll">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalPayroll" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreatePayroll" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<div class="row">
				<div class="col-md-4">
					<b>Socio</b>
					<a-select v-model="payroll.user_id" style="width: 100%">
						<a-select-option value="107 - José Luis"> José Luis Pozos </a-select-option>
						<a-select-option value="440 - Sughey"> Sughey López</a-select-option>
						<a-select-option value="439 - Fabián"> Fabián San Juan </a-select-option>
						<a-select-option value="345 - Ismael"> Ismael Gómez </a-select-option>
					</a-select>
				</div>
				<div class="col-md-4">
					<b>Monto de pago</b>
					<a-input autocomplete="off" prefix="$" v-money="money" v-model="payroll.amount" />
				</div>
				<div class="col-md-4">
					<b>Cuenta de retiro</b>
					<a-select style="width: 100%" v-model="destination">
						<a-select-option value="pacific">Azul</a-select-option>
						<a-select-option value="rls">Gris</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<b>Complemento del concepto de pago</b>
					<a-input autocomplete="off" v-model="payroll.description" />
				</div>
			</div>
		</a-modal>
		<a-modal width="50%" :visible="modalTaxVisible" title="Pago referenciado" :closable="true" @cancel="onCloseModalTax">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalTax" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateTax" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<taxesODC ref="modalTax" :visible="modalTaxVisible" @close="onCloseModalTax" />
		</a-modal>
		<a-modal width="50%" :visible="modalBankCommissionVisible" title="Registro de comisiones" :closable="true" @cancel="onCloseModalBankCommission">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalBankCommission" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateBankCommission" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<bankCommissionODC ref="modalBankCommission" :visible="modalBankCommissionVisible" @close="onCloseModalBankCommission" />
		</a-modal>
		<a-modal width="50%" :visible="modalRefundVisible" title="Devolución de pago" :closable="true" @cancel="onCloseModalRefundVisible">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalRefundVisible" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateRefund" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<refundComponent ref="modalRefund" @closeModal="onCloseModalRefundVisible" />
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import taxesODC from '@/components/taxesODC'
import bankCommissionODC from '@/components/bankCommissionODC'
import refundComponent from '@/components/refundComponent'
import { VMoney } from 'v-money'
import utilities from '@/services/utilities'

const dateFormat = 'DD-MM-YYYY'
const columns = [
	{
		title: '# ODC',
		scopedSlots: { customRender: 'id' },
		align: 'center',
	},
	{
		title: 'Fecha / Registro',
		scopedSlots: { customRender: 'order_date' },
		align: 'center',
	},
	{
		title: 'Tipo de pago',
		scopedSlots: { customRender: 'payment_type' },
		align: 'center',
	},
	{
		title: 'Pedido',
		scopedSlots: { customRender: 'order' },
		align: 'center',
	},
	{
		title: 'Total',
		scopedSlots: { customRender: 'amount' },
		align: 'center',
	},
	{
		title: 'Acciones',
		scopedSlots: { customRender: 'action' },
		align: 'center',
	},
]

export default {
	name: 'PurchaseOrders',
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		taxesODC,
		bankCommissionODC,
		refundComponent,
	},
	data() {
		return {
			destination: '',
			payroll: {
				user_id: '',
				amount: '',
				description: '',
			},
			columns: {
				P: [],
				R: [
					{
						title: 'Solicitud',
						scopedSlots: { customRender: 'description' },
						align: 'center',
					},
					{
						title: 'Orden de servicio',
						scopedSlots: { customRender: 'service_order' },
						align: 'center',
					},
					{
						title: 'Acciones',
						scopedSlots: { customRender: 'action' },
						align: 'center',
					},
				],
			},
			dateFormat,
			orderModal: false,
			elements: [],
			selectedRowKeys: [],
			modalTaxVisible: false,
			modalPayrollVisible: false,
			modalBankCommissionVisible: false,
			modalRefundVisible: false,
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			defaultDate: [moment().startOf('day'), moment().endOf('day')],
			cash: {
				loan: {
					s2r: {
						available: 0,
						out: 0,
					},
					rls: {
						available: 0,
						out: 0,
					},
				},
				panel: {
					available: 0,
					out: 0,
				},
				pacific: {
					available: 0,
					out: 0,
				},
				rls: {
					available: 0,
					out: 0,
				},
			},
			cardSection: '',
		}
	},
	computed: {
		...mapGetters('purchaseOrders', ['purchaseOrdersList', 'filters', 'totalPurchaseOrders', 'isCashODC']),
		...mapGetters('user', ['user', 'isUserAdmin']),
		...mapGetters('suppliers', ['suppliersList']),
		userPermition() {
			// zem - 17
			// pozos - 11
			// sughey - 13
			// ismael - 1
			// guadalupe - 27
			let allowedUserPermition = [1, 11, 13, 17, 27]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		isUserAdmin() {
			return !!this.user.is_admin
		},
		spinnerLoaderLabel() {
			return this.$store.state.purchaseOrders.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.purchaseOrders.spinnerLoader
		},
		showFilters() {
			return !['C', '0', '1'].includes(this.filters.status)
		},
		xlsxJsonData() {
			let localPurchaseOrdersList = this.purchaseOrdersList.filter((e) => !e.is_cash_odc)
			return localPurchaseOrdersList.length ? localPurchaseOrdersList : []
		},
	},
	mounted() {
		this.initModule()
		this.getSuppliersList()
	},
	methods: {
		numeral,
		moment,
		getColumns() {
			if (this.filters.status == 'R') {
				return this.columns.R
			} else {
				if (!this.columns.P.length) {
					this.columns.P = columns
				}
				return this.columns.P
			}
		},
		sectionSelected(section) {
			this.cardSection = section
		},
		onBudgetAllocation(destination) {
			let allowUsers = process.env.VUE_APP_USERS_TO_BUDGET_ALLOCATION.split(',').map((e) => Number(e))

			if (!allowUsers.includes(this.user.user_id)) {
				return false
			}

			Swal.fire({
				title: 'Ingresa monto a asignar',
				input: 'text',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!isNaN(value)) {
							if (!value || Number(value) < 1) {
								resolve('Es necesario ingresar un monto válido')
							}
						} else {
							resolve('Es necesario ingresar un monto válido')
						}
						resolve()
					})
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('warehouseCashflow/BUDGET_ALLOCATION', { amount: numeral(result.value).value(), destination }).then((response) => {
						Swal.fire({
							title: 'Flujos de caja',
							text: utilities.objectValidate(response, 'message', 'Fondeo generado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							this.getCashBalance()
						})
					})
				}
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		handleStatusChange(e) {
			this.$store.commit('purchaseOrders/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
					supplier_id: '',
				},
			})

			if (numeral(e.target.value).value() == 2) {
				let newColumns = []
				columns.forEach((element, index) => {
					if (index == 1) {
						newColumns.push({
							title: 'Fecha de pago',
							scopedSlots: { customRender: 'payment_date' },
							align: 'center',
						})
					}
					newColumns.push(element)
				})
				this.columns.P = _.cloneDeep(newColumns)
			} else {
				this.columns.P = _.cloneDeep(columns)
			}

			this.initModule()
		},
		initModule(needStatus = true) {
			//
			let payload = {
				status: this.filters.status == '' ? 'C' : needStatus ? this.filters.status : false,
				supplier_id: utilities.objectValidate(this.filters, 'supplier_id', false) ? this.filters.supplier_id : false,
			}

			if (this.defaultDate.length == 2) {
				payload = {
					...payload,
					startDate: moment(this.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.defaultDate[1]).format('YYYY-MM-DD'),
				}
			}

			// Si la sección es cotización o por autorizar, se retira rango de fecha
			if (!this.showFilters) {
				delete payload.startDate
				delete payload.endDate
			}

			this.$store.dispatch('purchaseOrders/GET', payload)

			// Saldos de panel
			this.getCashBalance()
		},
		getCashBalance() {
			let payload = {}
			if (this.filters.status == '2') {
				payload = {
					startDate: moment(this.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.defaultDate[1]).format('YYYY-MM-DD'),
				}
			}

			this.$store.dispatch('direction/GET_CASH_BALANCE', payload).then((response) => {
				this.cash.loan.s2r = {
					available: utilities.objectValidate(response, 'data.loan.s2r.totalIn', 0) - utilities.objectValidate(response, 'data.loan.s2r.totalOut', 0),
					out: utilities.objectValidate(response, 'data.loan.s2r.totalOut', 0),
				}
				this.cash.loan.rls = {
					available: utilities.objectValidate(response, 'data.loan.rls.totalIn', 0) - utilities.objectValidate(response, 'data.loan.rls.totalOut', 0),
					out: utilities.objectValidate(response, 'data.loan.rls.totalOut', 0),
				}
				this.cash.panel = {
					available: utilities.objectValidate(response, 'data.panel.totalIn', 0) - utilities.objectValidate(response, 'data.panel.totalOut', 0),
					out: utilities.objectValidate(response, 'data.panel.filterOut', 0),
				}
				this.cash.pacific = {
					available: utilities.objectValidate(response, 'data.pacific.totalIn', 0) - utilities.objectValidate(response, 'data.pacific.totalOut', 0),
					out: utilities.objectValidate(response, 'data.pacific.filterOut', 0),
				}
				this.cash.rls = {
					available: utilities.objectValidate(response, 'data.rls.totalIn', 0) - utilities.objectValidate(response, 'data.rls.totalOut', 0),
					out: utilities.objectValidate(response, 'data.rls.filterOut', 0),
				}
			})
		},
		onAddRecord(isCashODC = false, fromRLS = false) {
			this.$store.commit('purchaseOrders/SET_STATE', { isCashODC, fromRLS })
			this.$router.push('/purchase-orders/create')
		},
		onEditRecord(id, fromRLS = false) {
			this.$store.commit('purchaseOrders/SET_STATE', { fromRLS })
			this.$store.dispatch('purchaseOrders/SET_LOCAL', id)
		},
		onChangeStatus(id) {
			let total = this.purchaseOrdersList.find((e) => e.id == id).total

			if (!total) {
				//
				Swal.fire({
					title: 'Atención',
					html: `No se puede cambiar el estatus de la ODC sin establecer el monto total de la compra.`,
					icon: 'warning',
					confirmButtonText: 'Ok',
					cancelButtonText: 'Cancelar',
				})
			} else {
				Swal.fire({
					title: 'Atención',
					text: '¿Deseas autorizar la ODC?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar',
					cancelButtonText: 'Cancelar',
				}).then((result) => {
					if (result.isConfirmed) {
						this.$store
							.dispatch('purchaseOrders/UPDATE_STATUS', {
								id,
								status: 1,
							})
							.then(() => {
								this.initModule()
							})
					}
				})
			}
		},
		onDisableRecord(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas rechazar la ODC?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let total = this.purchaseOrdersList.find((e) => e.id == id).total
					let inputOptions = {}

					if (!total) {
						inputOptions = {
							3: 'INTERNO - Cambio/error de proveedor/método de pago',
							4: 'INTERNO - Cambio/error de unidad de medida',
							5: 'INTERNO - Cambio/error de cantidad',
							6: 'INTERNO - Cambio/error de total',
							7: 'INTERNO - Cambio/error de descripción',
						}
					} else {
						inputOptions = {
							1: 'PROVEEDOR - Cambio de precio/cotización',
							2: 'PROVEEDOR - Existencia/capacidad de entrega',
							3: 'INTERNO - Cambio/error de proveedor/método de pago',
							4: 'INTERNO - Cambio/error de unidad de medida',
							5: 'INTERNO - Cambio/error de cantidad',
							6: 'INTERNO - Cambio/error de total',
							7: 'INTERNO - Cambio/error de descripción',
						}
					}

					Swal.fire({
						title: 'Selecciona el motivo de rechazo',
						input: 'select',
						inputOptions,
						showCancelButton: true,
						confirmButtonText: 'Continuar',
						cancelButtonText: 'Cancelar',
						inputPlaceholder: 'Selecciona',
						inputValidator: (value) => {
							return new Promise((resolve) => {
								if (!isNaN(value)) {
									value = Number(value)
									if (!value || value < 0) {
										resolve('Es necesario elegir un motivo')
									}
								} else {
									resolve('Es necesario elegir un motivo')
								}
								resolve()
							})
						},
					}).then((resultReason) => {
						if (resultReason.value != '' && resultReason.isConfirmed) {
							//
							let reject_reason_id = Number(resultReason.value)
							let status = reject_reason_id == 1 || reject_reason_id == 2 ? 0 : -1

							let payload = {
								id,
								status,
								reject_reason_id,
								elements: this.purchaseOrdersList.find((e) => e.id == id).elements.map((e) => e.ie_id),
							}
							this.$store.dispatch('purchaseOrders/UPDATE_STATUS', payload)
						}
					})
				}
			})
		},
		authorizingUser(payload) {
			let response = ''
			let approved_by = JSON.parse(payload)
			response = `${approved_by.name} ${approved_by.first_lastname} ${approved_by.second_lastname || ''}`
			return response
		},
		resetVariables() {
			this.$store.dispatch('purchaseOrders/RESET_FILTERS')
			this.initModule()
		},
		onOpenModal(elements) {
			this.elements = _.cloneDeep(elements)
			this.orderModal = true
		},
		onCloseModal() {
			this.elements = []
			this.orderModal = false
		},
		onAuthorize() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar ODC a partir de los productos seleccionados?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				//
				if (result.isConfirmed) {
					let elements = this.purchaseOrdersList.map((e) => {
						if (this.selectedRowKeys.includes(e.id)) {
							return e
						}
					})
					let filtered = elements.filter((x) => {
						return x !== undefined
					})
					await this.$store.dispatch('impressment/CONFIRM', { elements: filtered, fromPurchaseOrders: true })
					this.selectedRowKeys = []
					this.initModule()
				}
			})
		},
		onDelete(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				//
				if (result.isConfirmed) {
					this.$store.dispatch('impressment/DELETE', { id, fromPurchaseOrders: true })
				}
			})
		},
		onAddRecordPayroll() {
			this.modalPayrollVisible = true
		},
		onAddRecordTax() {
			this.modalTaxVisible = true
		},
		async onCreatePayroll() {
			//
			let errors = []

			if (this.payroll.user_id == '') {
				errors.push(`- Selecciona socio`)
			}
			if (numeral(this.payroll.amount).value() < 1) {
				errors.push(`- Ingresa monto a pagar`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			} else {
				//
				let total = numeral(this.payroll.amount).value()
				let fullName = this.payroll.user_id.split(' - ')

				let payload = {
					total,
					elements: [
						{
							measurement_unit: 'service',
							quantity: 1,
							unit_cost: total,
							description: `Pago a socio - ${fullName[1]}${this.payroll.description != '' ? ` (${this.payroll.description})` : ''}`,
						},
					],
					supplier_id: fullName[0],
					payment_method_index: 0,
					payment_method_data: { payment_method: '2' },
					blocked: true,
					status: 1,
					is_payroll: 1,
					destination: this.destination,
				}

				await this.$store.dispatch('purchaseOrders/CREATE', { payload })
				this.onCloseModalPayroll()
			}
		},
		onCreateTax() {
			this.$refs.modalTax.onSaveRecord()
		},
		onCloseModalPayroll() {
			this.modalPayrollVisible = false
			setTimeout(() => {
				this.payroll = {
					user_id: '',
					amount: '',
				}
			}, 1000)
		},
		onCloseModalTax() {
			this.modalTaxVisible = false
		},
		onAddBankCommission() {
			this.modalBankCommissionVisible = true
		},
		onRefund() {
			this.modalRefundVisible = true
		},
		onCloseModalRefundVisible() {
			this.modalRefundVisible = false
			this.$refs.modalRefund.resetVariables()
		},
		onCreateBankCommission() {
			this.$refs.modalBankCommission.onSaveRecord()
		},
		onCreateRefund() {
			this.$refs.modalRefund.onSaveRecord()
		},
		onCloseModalBankCommission() {
			this.modalBankCommissionVisible = false
		},
		searchByTicket() {
			let payload = {
				ticket: this.filters.ticket,
			}
			this.$store.dispatch('purchaseOrders/GET', payload)
		},
		onResetTicket(e) {
			if (e.target.value == '') {
				this.initModule()
			}
		},
	},
}
</script>

<style scoped>
.cardSelected {
	border: 2px solid red !important;
}
</style>